@use '../../assets/scss/style.module.scss' as base;

.main {
    margin-top: 130px;

    .greeting {
        .conteiner {
            @include base.container;
        }

        .body {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            color: #000;

            .text {
                width: 50%;
                padding: 0 50px 0 0;
            }

            .location {
                @include base.subtitle;

                .location__svg {
                    width: 30px;
                    margin-right: 10px;
                    vertical-align: middle;
                }
            }

            .title {
                @include base.caption;
                font-weight: 700;
                margin-top: 20px;
                text-transform: uppercase;
            }

            .subtitle {
                @include base.subtitle;
                margin-top: 20px;
            }

            .confidentiality {
                @include base.subtitle;
                margin-top: 30px;
                background-color: #690079;
                color: #fff;
                border-radius: 1em;
                padding: 10px 20px;
                display: flex;
                align-items: center;
                text-align: center;
                font-weight: 500;

                img {
                    width: 40px;
                    margin-right: 10px;
                }
            }

            .img__lapm {
                width: 50%;
                border-radius: 2em;
            }
        }

        @media (max-width:768px) {
            .body {
                .text {
                    width: 100%;
                    padding: 0;
                }

                .img__lapm {
                    margin-top: 50px;
                    width: 100%;
                }
            }
        }
    }

    .scroll {
        margin: 50px 0;

        .wrapper {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            position: relative;
            background-color: #000;
        }

        .text {
            display: inline-block;
            @include base.caption;
            font-weight: 500;
            animation: scroll 10s linear infinite;
            color: #fff;

            span {
                margin-right: 150px;
            }
        }

        @keyframes scroll {
            0% {
                transform: translateX(100%);
            }

            100% {
                transform: translateX(-100%);
            }
        }
    }

    .advertisement {
        @include base.subtitle;

        h3 {
            display: inline;
            font-weight: 700;
            text-transform: uppercase;
        }

        img {
            width: 30px;
            vertical-align: middle;
            margin-right: 10px
        }

        .conteiner {
            @include base.container;
        }
    }

    .chip {
        margin-top: 40px;

        .container {
            @include base.container;
        }

        .body {
            @include base.subtitle;
        }

        span {
            text-transform: uppercase;
            font-weight: 700;
        }

        img {
            margin-right: 10px;
            width: 33px;
            vertical-align: middle;
        }

        .btn {
            text-align: center;
            margin-top: 30px;
            display: block;
            background-color: #690079;
            color: #fff;
            border-radius: 1em;
            padding: 10px 15px;
            font-weight: 700;
        }

        .btn__svg {
            margin-left: 15px;
            transform: rotate(180deg);
        }
    }

    .carts {
        margin-top: 50px;

        .conteiner {
            @include base.container;
        }

        .caption {
            @include base.title;
            font-weight: 500;
            font-weight: 700;
        }

        .body {
            margin-top: 40px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;
            @include base.subtitle;
        }

        .cart {
            margin-bottom: 30px;
            width: 100%;
            background-color: #dbdae0;
            padding: 25px 15px;
            border-radius: 1em;
            text-align: center;

            &:nth-child(2) {
                background-color: #690079;
                color: #dbdae0;
            }
        }

        .title {
            font-weight: 500;
            text-transform: uppercase;
        }

        .subtitle {
            margin-top: 20px;
        }
    }

    .safety {
        margin-top: 50px;

        .container {
            @include base.container;
        }

        .body {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .title {
            width: 10%;
            writing-mode: vertical-lr;
            transform: rotate(180deg);
            @include base.title;
            font-weight: 600;
            text-transform: uppercase;
        }

        .text {
            padding-left: 20px;
            width: 90%;
            @include base.subtitle;
        }

        p {
            margin-bottom: 20px;
        }

        img {
            width: 25px;
            vertical-align: middle;
            margin-right: 7px;
        }

        span {
            font-weight: 600;
        }
    }

    .order {
        margin-top: 50px;

        .container {
            @include base.container;
        }

        .body {
            @include base.subtitle;
        }

        .title {
            display: inline-block;
            background-color: #dbdae0;
            border-radius: 1em 1em 0 0;
            padding: 10px 20px 0;
            font-weight: 600;
        }

        .text {
            background-color: #dbdae0;
            padding: 10px 20px;
            border-radius: 0em 1em 1em 1em;
        }

        span {
            font-weight: 600;
        }
    }

    .button {
        margin: 50px 0 70px 0;

        .container {
            @include base.container;
            position: relative;
        }

        a {
            color: #000;
        }

        .bg {
            display: block;
            margin: 0 auto;
            filter: brightness(60%);
            border-radius: 2em;
            height: 100px;
            width: 60%;
            object-fit: cover;
        }

        button {
            position: absolute;
            top: 50%;
            left: 50%;
            @include base.subtitle;
            transform: translate(-50%, -50%);
            background-color: #dbdae0;
            padding: 10px 20px;
            border-radius: 2em;
            font-weight: 500;
        }

        .telegram__svg {
            margin-left: 5px;
            width: 40px;
            vertical-align: middle;
        }

        @media (max-width:768px) {
            .bg {
                width: 100%;
            }

            button {
                padding: 5px 10px;
                white-space: nowrap;
            }
        }
    }
}