@use '../../assets/scss/style.module.scss' as base;

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main {
    margin-top: 100px;

    .container {
        @include base.container;
    }

    .caption {
        @include base.caption;

        span {
            @include base.title;
        }
    }

    .items {
        margin-top: 40px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        gap: 40px;
    }

    .additionally {
        text-align: center;
        margin: 50px 0 45px 0;

        p {
            display: inline-flex;
            @include base.subtitle;
            background-color: #690079;
            padding: 20px 25px;
            border-radius: 1em;
            color: #fff;
            font-weight: 500;
        }

        .telegram {
            margin: 20px auto 0 auto;
            display: block;
            text-align: center;
            @include base.subtitle;
            width: 200px;
            padding: 10px 15px;
            background-color: #000;
            border-radius: 2em;
            color: aliceblue;
            font-weight: 500;
            text-transform: uppercase;
        }

        .telegram__svg {
            margin-left: 10px;
            width: 30px;
            vertical-align: middle;
        }
    }

    @media (max-width:500px) {
        .items {
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        }
    }
}