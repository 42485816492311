$maxWidth: 1430;

@mixin adaptiv-font($pcSize, $mobSize) {
    $addSize: $pcSize - $mobSize;
    $addMobSize: $addSize + $addSize * 0.7;

    @media (max-width: 767px) {
        font-size: calc(#{$mobSize + px} + #{$addMobSize} * ((100vw - 320px) / #{$maxWidth}));
    }

    @media (min-width: 767px) {
        font-size: calc(#{$mobSize + px} + #{$addMobSize} * (100vw / #{$maxWidth}));
    }
}