@use '../../assets/scss/style.module.scss' as base;

.header {
    position: fixed;
    top: 0;
    left: 0;
    height: 70px;
    width: 100vw;
    background-color: #e5e6e9;
    z-index: 9999;

    h1 {
        display: inline;
    }

    .container {
        @include base.container;
    }

    .body {
        line-height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &::after {
            content: '';
            position: absolute;
            bottom: 0%;
            left: 50%;
            height: 1px;
            width: 90%;
            background: #BDC1C4;
            transform: translateX(-50%);
        }
    }

    .logo {
        color: #000000;
        font-size: 24px;
    }

    .logo__svg {
        width: 30px;
        vertical-align: middle;
        margin-right: 2px;
    }

    .burger {
        display: none;
    }

    .menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .link {
        margin-left: 20px;
        cursor: pointer;
        transition: all 500ms ease;
        color: #000;
        @include base.subtitle;

        &:hover {
            transform: scale(1.1);
        }
    }

    .telegram {
        font-size: 18px;
        line-height: 30px;
        color: #fff;
        font-weight: 500;
        background-color: #000;
        padding: 5px 15px;
        border-radius: 2em;
        transition: all 250ms ease-in-out;

        &:hover {
            background-color: #fff;
            color: #000;
        }
    }
}

@media (max-width:768px) {
    .header {
        .body {
            position: relative;
        }

        .burger {
            display: block;
            width: 30px;
            height: 40px;
            margin-right: 10px;
            position: relative;

            span {
                position: absolute;
                background-color: #575252;
                width: 100%;
                height: 3px;
                border-radius: 2em;
            }

            &::after {
                content: '';
                position: absolute;
                background-color: #575252;
                width: 100%;
                height: 3px;
                border-radius: 2em;
            }

            &::before {
                content: '';
                position: absolute;
                background-color: #575252;
                width: 100%;
                height: 3px;
                border-radius: 2em;
            }
        }

        .burger__active {
            span {
                animation-name: burger-middle;
                animation-duration: 1s;
                animation-fill-mode: forwards;
            }

            &::after {
                animation-name: burger-upper;
                animation-duration: 1s;
                animation-fill-mode: forwards;
            }

            &::before {
                animation-name: burger-lower;
                animation-duration: 1s;
                animation-direction: alternate;
                animation-fill-mode: forwards;
            }
        }

        @keyframes burger-upper {
            0% {
                top: 20%;
            }

            30% {
                top: 50%;
                transform: rotate(0deg);
            }

            100% {
                top: 50%;
                transform: rotate(50deg);
            }
        }

        @keyframes burger-middle {
            0% {
                top: 50%;
                transform: translateY(-50%);
                transform: rotate(0deg);
            }

            40% {
                overflow: hidden;
                opacity: 0;
            }

            100% {
                overflow: hidden;
                opacity: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        @keyframes burger-lower {
            0% {
                bottom: 20%;
            }

            30% {
                top: 50%;
                transform: rotate(0deg);
            }

            100% {
                top: 50%;
                transform: rotate(130deg);
            }
        }

        .burge__revers {
            span {
                animation-name: burger-middle-revers;
                animation-duration: 1s;
                animation-fill-mode: forwards;
            }

            &::after {
                animation-name: burger-upper-revers;
                animation-duration: 1s;
                animation-fill-mode: forwards;
            }

            &::before {
                animation-name: burger-lower-revers;
                animation-duration: 1s;
                animation-direction: alternate;
                animation-fill-mode: forwards;
            }
        }

        @keyframes burger-upper-revers {
            0% {
                transform: rotate(50deg);
                top: 50%;
                transform: translateY(-50%);
            }

            50% {
                transform: rotate(0deg);
            }

            100% {
                top: 20%;
            }
        }

        @keyframes burger-middle-revers {
            0% {
                overflow: hidden;
                opacity: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            100% {
                overflow: visible;
                opacity: 1;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        @keyframes burger-lower-revers {
            0% {
                bottom: 50%;
                transform: translateY(-50%);
                transform: rotate(130deg);
            }

            50% {
                transform: rotate(0deg);
            }

            100% {
                bottom: 20%;
                transform: rotate(0deg);
            }
        }

        .menu {
            position: fixed;
            z-index: 9999;
            top: 70px;
            left: -100%;
            width: 100%;
            height: 100vh;
            background-color: #bdc1c4;
            transition: all 700ms ease;
            border-radius: 2em;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .menu__active {
            left: 0% !important;
        }

        .link {
            display: block;
            margin: 0 0 40px 0;
            font-size: 30px;
            font-weight: 500;

            &:hover {
                transform: scale(1);
            }
        }

        .telegram {
            font-size: 26px;
            width: 200px;
            text-align: center;
            padding: 10px;
            border-radius: 1em;
        }
    }
}