@use '../../assets/scss/style.module.scss' as base;

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main {
    margin: 120px 0 100px;

    .container {
        @include base.container;
    }

    h2 {
        @include base.title;
    }

    .text {
        margin-top: 50px;
    }

    p {
        @include base.subtitle;
        margin-top: 30px;
    }

    span {
        font-weight: 700;
    }
}