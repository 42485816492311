@use '../../assets/scss/style.module.scss' as base;

.item {

    .wrapper {
        position: relative;
        border-radius: 2em;
        width: auto;
        height: 350px;
    }

    .text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 20px 25px;
        @include base.subtitle;
        display: flex;
        flex-direction: column;
    }

    .title {
        text-align: center;
        color: aliceblue;
        font-weight: 500;
        text-transform: uppercase;
        background-color: #690079;
        padding: 5px 20px;
        border-radius: 1em;
    }

    .panels {
        margin-top: auto;
        display: flex;
        justify-content: space-around;
    }

    .panel {
        background-color: #000;
        padding: 10px 15px;
        color: aliceblue;
        border-radius: 2em;
        position: relative;
    }

    .telegram {
        margin: 20px auto 0 auto;
        display: block;
        text-align: center;
        @include base.subtitle;
        width: 200px;
        padding: 10px 15px;
        background-color: #000;
        border-radius: 2em;
        color: aliceblue;
        font-weight: 500;
        text-transform: uppercase;
    }

    .telegram__svg {
        margin-left: 10px;
        width: 30px;
        vertical-align: middle;
    }

    @media (max-width:425px) {
        .panels {
            font-size: 16px;
        }

        .position {
            position: absolute;
            bottom: 20%;
            right: 10%;
        }
    }
}