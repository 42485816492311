@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

body,
html {
    height: 100%;
}

body {
    background-color: #e5e6e9;
    font-family: "Manrope", sans-serif;
}

@mixin caption {
    @include adaptiv-font(38, 30);
}

@mixin title {
    @include adaptiv-font(28, 24);
}

@mixin subtitle {
    @include adaptiv-font(20, 18);
}

@mixin link {
    @include adaptiv-font(18, 18);
}

@mixin container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 25px;
}